/* App.css */

body {
  background-color: #121212;
  font-family: "Arial", sans-serif;
  height: 100vh;
  overflow: hidden;
}

.Layout {
  height: 100vh;
  overflow: hidden;
}

@supports (-webkit-touch-callout: none) {
  body {
    height: -webkit-fill-available;
  }

  .Layout {
    height: -webkit-fill-available;
  }
}

.ant-layout {
  background: none;
}

.ant-layout-header {
  background-color: #282828;
  border-bottom: 1px solid #444;
}

.ant-btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.ant-btn {
  border-radius: 4px;
}

.ant-space {
  justify-content: center;
}

.floating-action-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 100;
}

.site-layout-content {
  padding: 20px;
  text-align: center;
  width: 600px;
  margin: auto;
  position: relative;
}

@media (max-width: 600px) {
  .floating-action-button {
    right: 20px;
    bottom: 20px;
  }

  .site-layout-content {
    width: 100%;
  }
}
